// import React from "react";
// import { Button, Carousel } from "antd";
// import "./index.scss";
// import { LeftOutlined, RightOutlined } from "@ant-design/icons";
// import Link from "next/link";

// const NextArrow = ({ currentSlide, slideCount, ...props }) => (
//   <Button
//     {...props}
//     className="hunarmand-arrow hunarmand-arrow-next"
//     icon={<RightOutlined />}
//     // shape="circle"
//   />
// );

// const PrevArrow = ({ currentSlide, slideCount, ...props }) => (
//   <Button
//     {...props}
//     className="hunarmand-arrow hunarmand-arrow-prev"
//     icon={<LeftOutlined />}
//     // shape="circle"
//   />
// );

// const HunarmandProducts = ({
//   userAgent,
//   data,
//   title,
//   description,
//   buttonText,
//   buttonLink,
//   buttonColor,
// }) => {
//   const { isMobile } = userAgent;
//   const sectionsList = data?.products;

//   const carouselSettings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: !isMobile ? 4 : 2,
//     slidesToScroll: 1,
//     arrows: true,
//     autoplay: false,
//     swipeToSlide: true,
//     centerPadding: "20px",
//     draggable: true,
//     className: isMobile ? "hunarmand-carousel-mobile" : "hunarmand-carousel",
//     centerMode: false,
//     nextArrow: <NextArrow />,
//     prevArrow: <PrevArrow />,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 4,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   console.log("Hunarmand Carousel Data is:", data);

//   return (
//     <section
//       className={
//         isMobile ? "hunarmand-container-mobile" : "hunarmand-section-container"
//       }
//     >
//       {!isMobile ? (
//         <div className="hunarmand-desktop-wrapper">
//           <div className="hunarmand-text-section">
//             <h1 className="hunarmand-section-title">{title}</h1>
//             <p className="hunarmand-description">{description}</p>
//             <div className="hunarmand-view-more-container">
//               {buttonLink && (
//                 <Button className="hunarmand-button" type={buttonColor}>
//                   <Link href={buttonLink}>{buttonText}</Link>
//                 </Button>
//               )}
//             </div>
//           </div>
//           <div className="hunarmand-carousel-section">
//             <Carousel {...carouselSettings}>
//               {sectionsList?.map((item, index) => (
//                 <div key={index} className="hunarmand-item">
//                   <Link href={item.handle}>
//                     <div className="hunarmand-image-container">
//                       <img
//                         src={item.images[0]}
//                         alt={item.title}
//                         className="hunarmand-image"
//                       />
//                     </div>
//                     <p className="hunarmand-label">{item.title}</p>
//                   </Link>
//                 </div>
//               ))}
//             </Carousel>
//           </div>
//         </div>
//       ) : (
//         <div className="hunarmand-mobile-wrapper">
//           <div className="hunarmand-text-section-mobile">
//             <h1 className="hunarmand-title-mobile">{title}</h1>
//             <p className="hunarmand-description-mobile">{description}</p>
//           </div>
//           <div className="hunarmand-carousel-section-mobile">
//             <Carousel {...carouselSettings}>
//               {sectionsList?.map((item, index) => (
//                 <div key={index} className="hunarmand-item-mobile">
//                   <Link href={item.handle}>
//                     <div className="hunarmand-image-container-mobile">
//                       <img
//                         src={item.images[0]}
//                         alt={item.title}
//                         className="hunarmand-image-mobile"
//                       />
//                     </div>
//                     <p className="hunarmand-label-mobile">{item.title}</p>
//                   </Link>
//                 </div>
//               ))}
//             </Carousel>
//           </div>
//           <div className="hunarmand-view-more-container-mobile">
//             <Button className="hunarmand-button-mobile" type={buttonColor}>
//               <Link href={buttonLink}>{buttonText}</Link>
//             </Button>
//           </div>
//         </div>
//       )}
//     </section>
//   );
// };

// export default HunarmandProducts;

import React from "react";
import { Button, Carousel } from "antd";
import "./index.scss";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import Link from "next/link";

const NextArrow = ({ currentSlide, slideCount, ...props }) => (
  <Button
    {...props}
    className="hunarmand-arrow hunarmand-arrow-next"
    icon={<RightOutlined />}
    // shape="circle"
  />
);

const PrevArrow = ({ currentSlide, slideCount, ...props }) => (
  <Button
    {...props}
    className="hunarmand-arrow hunarmand-arrow-prev"
    icon={<LeftOutlined />}
    // shape="circle"
  />
);

const HunarmandProducts = ({
  userAgent,
  data,
  title,
  description,
  buttonText,
  buttonLink,
  buttonColor,
}) => {
  const { isMobile } = userAgent;
  const sectionsList = data?.products;

  // Calculate optimal slidesToShow based on available items
  const optimalSlidesToShow = !isMobile
    ? Math.min(sectionsList?.length || 4, 4) // For desktop, show at most 4 or the number of available items
    : Math.min(sectionsList?.length || 2, 2); // For mobile, show at most 2 or the number of available items

  const carouselSettings = {
    dots: false,
    infinite: false, // Change to false to prevent duplication
    speed: 500,
    slidesToShow: optimalSlidesToShow,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    swipeToSlide: true,
    centerPadding: "20px",
    draggable: true,
    className: isMobile ? "hunarmand-carousel-mobile" : "hunarmand-carousel",
    centerMode: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(sectionsList?.length || 4, 4),
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(sectionsList?.length || 2, 2),
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Math.min(sectionsList?.length || 2, 2),
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };

  return (
    <section
      className={
        isMobile ? "hunarmand-container-mobile" : "hunarmand-section-container"
      }
    >
      {!isMobile ? (
        <div className="hunarmand-desktop-wrapper">
          <div className="hunarmand-text-section">
            <h1 className="hunarmand-section-title">{title}</h1>
            <p className="hunarmand-description">{description}</p>
            <div className="hunarmand-view-more-container">
              {buttonLink && (
                <Button className="hunarmand-button" type={buttonColor}>
                  <Link href={buttonLink}>{buttonText}</Link>
                </Button>
              )}
            </div>
          </div>
          <div className="hunarmand-carousel-section">
            <Carousel {...carouselSettings}>
              {sectionsList?.map((item, index) => (
                <div key={index} className="hunarmand-item">
                  <Link href={item.handle}>
                    <div className="hunarmand-image-container">
                      <img
                        src={item.images[0]}
                        alt={item.title}
                        className="hunarmand-image"
                      />
                    </div>
                    <p className="hunarmand-label">{item.title}</p>
                  </Link>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      ) : (
        <div className="hunarmand-mobile-wrapper">
          <div className="hunarmand-text-section-mobile">
            <h1 className="hunarmand-title-mobile">{title}</h1>
            <p className="hunarmand-description-mobile">{description}</p>
          </div>
          <div className="hunarmand-carousel-section-mobile">
            <Carousel {...carouselSettings}>
              {sectionsList?.map((item, index) => (
                <div key={index} className="hunarmand-item-mobile">
                  <Link href={item.handle}>
                    <div className="hunarmand-image-container-mobile">
                      <img
                        src={item.images[0]}
                        alt={item.title}
                        className="hunarmand-image-mobile"
                      />
                    </div>
                    <p className="hunarmand-label-mobile">{item.title}</p>
                  </Link>
                </div>
              ))}
            </Carousel>
          </div>
          <div className="hunarmand-view-more-container-mobile">
            {buttonLink && (
              <Button className="hunarmand-button-mobile" type={buttonColor}>
                <Link href={buttonLink}>{buttonText}</Link>
              </Button>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default HunarmandProducts;

import { Modal, Typography, Carousel } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import "./DiscountModal.scss";

const DetailNoteModal = ({ openDiscountModal, showDiscountModal }) => {
  const mainColor = Cookies.get("main_theme");
  const secondaryColor = Cookies.get("secondary_theme");

  const handleClick = () => {
    showDiscountModal(false);
  };
  const settings = {
    autoplaySpeed: 4000,
    dots: false,
    swipe: true,
    autoplay: true,
    infinite: true,
    arrows: false,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: true,
  };
  return (
    <Modal
      open={openDiscountModal}
      onCancel={handleClick}
      footer={null}
      centered
      closeIcon={
        <CloseOutlined
          className="detail-modal-close"
          style={{
            backgroundColor: "rgb(214, 35, 20)",
            borderRadius: "5px",
            padding: "4px",
          }}
        />
      }
      bodyStyle={{
        padding: 10,
      }}
      className="detail-description-modal"
    >
      <Carousel {...settings}>
        <div className="detail-description-container">
          <h3 className="detail-description-title">Karhai Kahani</h3>
          <Typography.Text style={{ marginBottom: "10px" }}>
            Nestled in the heart of Khairpur, Sindh—a city celebrated for its
            rich history, iconic sites, and vibrant culture—its historic forts,
            ancient tombs, and beautiful landscapes. The art of Karhai—hand
            embroidery—is more than a craft; it’s a visual narrative of cultural
            heritage and architectural beauty. The artisans of Khairpur,
            inspired by the intricate designs of local mosques and historical
            sites, infuse these influences into every stitch of their craft.
            Whether on shawls, garments, or decorative sheets, the detailed
            Karhai is a marvel of skill, with patterns that evoke the geometry
            of tiled domes and the symmetry of ancient arches. Using techniques
            like Sindhi Taka and Kundi ka Kam, the embroiderers create pieces
            alive with vivid colors, mirrorwork, and motifs of florals,
            peacocks, and geometric elegance. Each finished item—a shawl, a
            garment, or a bedspread—embodies a legacy of tradition and artistry,
            making every piece not just functional but a cultural treasure.
          </Typography.Text>
        </div>
        <div className="detail-description-container">
          <h3 className="detail-description-title">Ralli Kahani</h3>
          <Typography.Text style={{ marginBottom: "10px" }}>
            In the heart of Khairpur, a city known for its craft and resilience,
            the legacy of Khairpur city is shaped by the hands of women who
            weave stories into life. Here, faded colors & worn-out cloths find
            new life. Old fabrics, discarded by some, are reborn by these
            women's hands, turned into Ralli. These Rallis are woven with
            handywork of expert craftswomen and their story has resonated with
            the world. Long before the world called it "sustainable," for them,
            it was a tradition. Creating something beautiful out of nothing is
            at the heart of Sindh's culture. The creation of a Ralli quilt is a
            timeless tradition, transforming simple fabric into profound art.
            Each Ralli quilt carries stories of the artisan who stitched it,
            woven with care, resilience, and a connection to the community’s
            cultural roots.
          </Typography.Text>
        </div>
      </Carousel>
    </Modal>
  );
};

export default DetailNoteModal;

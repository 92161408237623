"use client";

import React from "react";
import { Button, Image } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useRouter } from "next/navigation";
import BackArrow from "../../../../assets/Icons/BackArrow.svg";
import BagIcon from "../../../../assets/Icons/BagIcon.svg";
import Cookies from "js-cookie";
import Link from "next/link";

const MobileTopNav = ({ title, bagButton }) => {
  let cartId = Cookies.get("cartId");
  const mainColor = Cookies.get("main_theme");
  const secondaryColor = Cookies.get("secondary_theme");
  const router = useRouter();

  const styles = {
    navContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 0px 20px 0px",
      backgroundColor: "#ffffff",
      position: "relative",
      top: 0,
      width: "96%",
    },
    backButton: {
      backgroundColor: mainColor,
      color: "#ffffff",
      border: "none",
      justifyContent: "center",
      alignItems: "center",
    },
    titleContainer: {
      marginLeft: "10px",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
    },
    title: {
      fontSize: "18px",
      color: "#001f54",
      fontFamily: "ZemestroStd-Regular",
      textTransform: "capitalize",
      lineHeight: "25px",
      width: "99%",
    },
    emptySpace: {
      width: "40px",
    },
  };

  return (
    <div style={styles.navContainer}>
      <Button
        icon={
          <Image alt="back" src={BackArrow.src} width={10} preview={false} />
        }
        style={{
          ...styles.backButton,
          width: "40px",
          height: "40px",
          borderRadius: "10px",
        }}
        onClick={() => router.back()}
      />

      <div style={styles.titleContainer}>
        {title ? (
          <div style={styles.title}>
            {title.includes("/") ? title.replace(/\//g, " - ") : title}
          </div>
        ) : null}
      </div>

      <div style={styles.emptySpace}>
        {bagButton && (
          <Link
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: mainColor,
              width: "40px",
              height: "40px",
              borderRadius: "10px",
            }}
            href={cartId ? `/cart/checkout?id=${cartId}` : `/`}
          >
            <Image
              preview={false}
              height={22}
              width="auto"
              alt="wishlist"
              src={BagIcon.src}
            />
          </Link>
        )}
      </div>
    </div>
  );
};

export default MobileTopNav;

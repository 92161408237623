"use client";
//? Library Imports ------------------------------------------------------------>
import {
  Col,
  Image,
  Carousel,
  Typography,
  // Row,
  // Card,
  // Skeleton
} from "antd";
import Link from "next/link";
import { LazyLoadImage } from "react-lazy-load-image-component";

//? Assets --------------------------------------------------------------------->
import "./BannerCarousel.scss";
import { useEffect, useState, useRef, useCallback } from "react";
import CustomSkeletonBox from "@/components/CustomSkeletonBox/CustomSkeletonBox";
import { logoutReducer } from "@/redux/Slices/accountSlice";
import { customerLogout } from "@/functions/Cookies/Cookies";
import {
  SlickNextArrow,
  SlickPrevArrow,
} from "@/components/shared/Buttons/CarouselButtons/CarouselButtons";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

// Custom Next Arrow
const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "10px", zIndex: "1" }}
      onClick={onClick}
    >
      <ArrowForwardIosIcon />
    </div>
  );
};

// Custom Previous Arrow
const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "10px", zIndex: "1" }}
      onClick={onClick}
    >
      <ArrowBackIosIcon />
    </div>
  );
};

export default function BannerCarousel({ DashboardDetails, data, userAgent }) {
  const { isDesktop, isMobile } = userAgent;
  const [isHovered, setIsHovered] = useState(false);
  // const [loading, setLoading] = useState(true);
  const [imageSrcs, setImageSrcs] = useState([]);
  const Title = Typography;
  const settings = {
    speed: isMobile ? 1000 : 5000,
    autoplaySpeed: 4000,
    dots: isMobile ? true : false,
    swipe: true,
    autoplay: true,
    infinite: true,
    arrows: isMobile ? false : true,
    slidesToScroll: 1,
    fade: isMobile ? false : true,
    pauseOnHover: true,
    prevArrow: isMobile ? "" : <PrevArrow />,
    nextArrow: isMobile ? "" : <NextArrow />,
  };

  useEffect(() => {
    // setLoading(false);
  }, [data?.slides]);

  const handleImageError = (index) => {
    const newImageSrcs = [...imageSrcs];
    newImageSrcs[index] = data.slides[index].img;
    setImageSrcs(newImageSrcs);
  };

  return (
    <>
      {data?.section_type === "layout_1" && (
        <Col className="BannerCarousel banner_carousel marginBottom15">
          <div
            ontouchstart={() => {
              setIsHovered(true);
            }}
          >
            {/* {DashboardDetails?.profile?.full_name ? (
              <Title className=" font24 marginLeft5">
                <span className="heading-semibold-dark"></span> Welcome{" "}
                <span className="heading-bold-primary">
                  {DashboardDetails?.profile?.full_name}
                </span>
              </Title>
            ) : null} */}

            <Carousel {...settings} swipeToSlide draggable>
              {data?.slides?.map((slide, index) => (
                <Link key={index} href={slide?.link}>
                  <Image
                    alt={
                      slide?.banner_slider_alt_text
                        ? slide?.banner_slider_alt_text
                        : slide?.img?.split("/").pop()
                    }
                    preview={false}
                    // onError={() => handleImageError(index)}
                    src={slide?.img}
                    width="100%"
                    height="auto"
                  />
                </Link>
              ))}
            </Carousel>
            {/* )} */}
          </div>
        </Col>
      )}
      {data?.section_type === "layout_2" && (
        <div className="bannerCarouselHomepage">
          <Col span={24} className="sectionHeadingContainer">
            <Title
              className={
                !isDesktop
                  ? "sectionHeadingMobileBold textLeft"
                  : "sectionHeadingBold textLeft"
              }
            >
              {data?.main_title}
            </Title>
          </Col>
          <div className="slider-container">
            {data?.slides?.map((slide, index) => (
              <div className="slide" key={index}>
                <Link href={slide?.link || "#"}>
                  <LazyLoadImage
                    alt={slide?.banner_slider_alt_text}
                    src={slide?.img}
                    effect="blur"
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

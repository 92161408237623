import React, { useEffect, useState } from "react";
import "./SaleCounter.css"; // Import your CSS for styling

const calculateTimeLeft = (targetDate) => {
  const now = new Date();
  const difference = targetDate - now;

  let timeLeft = {};
  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      ),
      minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((difference % (1000 * 60)) / 1000),
    };
  }
  return timeLeft;
};

const SaleCounter = () => {

  // Updated target date: January 31, 2025, 23:59:59
  const targetDate = new Date("2025-01-31T23:59:59");

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));
  const [prevTimeLeft, setPrevTimeLeft] = useState(timeLeft);

  useEffect(() => {
    const timer = setInterval(() => {
      setPrevTimeLeft(timeLeft);
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate, timeLeft]);

  const { days, hours, minutes, seconds } = timeLeft;
  const {
    days: prevDays,
    hours: prevHours,
    minutes: prevMinutes,
    seconds: prevSeconds,
  } = prevTimeLeft;

  return (
    <div className="countdown-timer">
      <div className={`time-unit ${days !== prevDays ? "flip" : ""}`}>
        <div className={`number ${days !== prevDays ? "flip" : ""}`}>
          {days !== undefined ? days : "0"}
        </div>
        <div className="label">Days</div>
      </div>
      <div className={`time-unit ${hours !== prevHours ? "flip" : ""}`}>
        <div className={`number ${hours !== prevHours ? "flip" : ""}`}>
          {hours !== undefined ? hours : "0"}
        </div>
        <div className="label">Hours</div>
      </div>
      <div className={`time-unit ${minutes !== prevMinutes ? "flip" : ""}`}>
        <div className={`number ${minutes !== prevMinutes ? "flip" : ""}`}>
          {minutes !== undefined ? minutes : "0"}
        </div>
        <div className="label">Minutes</div>
      </div>
      <div className={`time-unit ${seconds !== prevSeconds ? "flip" : ""}`}>
        <div className={`number ${seconds !== prevSeconds ? "flip" : ""}`}>
          {seconds !== undefined ? seconds : "0"}
        </div>
        <div className="label">Seconds</div>
      </div>
    </div>
  );
};

export default SaleCounter;

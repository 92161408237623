// SortDrawer.js
import { Drawer, Radio } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import NextImage from "next/image";
import TopSellerIcon from "../../assets/images/collection page/mobile/sort icons/topseller.svg";
import TopSellerSelIcon from "../../assets/images/collection page/mobile/sort icons/topseller-selected.svg";
import NewestIcon from "../../assets/images/collection page/mobile/sort icons/new.svg";
import NewestSelIcon from "../../assets/images/collection page/mobile/sort icons/new-selected.svg";
import PriceLowIcon from "../../assets/images/collection page/mobile/sort icons/lowtohigh.svg";
import PriceLowSelIcon from "../../assets/images/collection page/mobile/sort icons/lowtohigh-selected.svg";
import PriceHighIcon from "../../assets/images/collection page/mobile/sort icons/hightolow.svg";
import PriceHighSelIcon from "../../assets/images/collection page/mobile/sort icons/hightolow-selected.svg";
import DiscountIcon from "../../assets/images/collection page/mobile/sort icons/discount.svg";
import DiscountSelIcon from "../../assets/images/collection page/mobile/sort icons/discount-selected.svg";
import HandpickIcon from "../../assets/images/collection page/mobile/sort icons/handpick.svg";
import HandpickSelIcon from "../../assets/images/collection page/mobile/sort icons/handpick-selected.svg";
import "./CollectionPage.scss";


const SortDrawer = ({ visible, onClose, onSortChange, sortOption }) => {
  return (
    <Drawer
      className="sort-drawer"
      title={
        <div className="drawer-header">
          <div className="drawer-title">Sort</div>
          <CloseCircleFilled
            onClick={onClose}
            className="close-icon"
          />
        </div>
      }
      placement="bottom"
      closable={false}
      onClose={onClose}
      open={visible}
      height={300}
    >
      <div className="radio-group-container">
        <Radio.Group
          onChange={(e) => {
            onSortChange(e.target.value);
            onClose();
          }}
          value={sortOption || "newest"}
        >
          <div className={`radio-option ${(sortOption === "newest" || !sortOption) ? "radio-checked" : ""}`}>
            <div className="sort-icon">
              <NextImage alt="" src={sortOption === "newest" ? NewestSelIcon.src : NewestIcon.src} width={24} height={24} />
            </div>
            <Radio value="newest">Newest</Radio>
          </div>

          <div className={`radio-option ${sortOption === "price_asc" ? "radio-checked" : ""}`}>
            <div className="sort-icon">
              <NextImage alt="" src={sortOption === "price_asc" ? PriceLowSelIcon.src : PriceLowIcon.src} width={24} height={24} />
            </div>
            <Radio value="price_asc">Price (Low to High)</Radio>
          </div>

          <div className={`radio-option ${sortOption === "price_des" ? "radio-checked" : ""}`}>
            <div className="sort-icon">
              <NextImage alt="" src={sortOption === "price_des" ? PriceHighSelIcon.src : PriceHighIcon.src} width={24} height={24} />
            </div>
            <Radio value="price_des">Price (High to Low)</Radio>
          </div>

          <div className={`radio-option ${sortOption === "discounts" ? "radio-checked" : ""}`}>
            <div className="sort-icon">
              <NextImage alt="" src={sortOption === "discounts" ? DiscountSelIcon.src : DiscountIcon.src} width={24} height={24} />
            </div>
            <Radio value="discounts">Top Discounts</Radio>
          </div>

          <div className={`radio-option ${sortOption === "sellers" ? "radio-checked" : ""}`}>
            <div className="sort-icon">
              <NextImage alt="" src={sortOption === "sellers" ? TopSellerSelIcon.src : TopSellerIcon.src} width={24} height={24} />
            </div>
            <Radio value="sellers">Top Sellers</Radio>
          </div>

          <div className={`radio-option ${sortOption === "manual" ? "radio-checked" : ""}`}>
            <div className="sort-icon">
              <NextImage alt="" src={sortOption === "manual" ? HandpickSelIcon.src : HandpickIcon.src} width={24} height={24} />
            </div>
            <Radio value="manual">Handpicked</Radio>
          </div>
        </Radio.Group>
      </div>
    </Drawer>
  );
};

export default SortDrawer;

// VideoPlayer.js
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import "./VideoPlayer.scss";
import { PauseCircleOutline, PlayCircleOutline } from "@mui/icons-material";

const VideoPlayer = ({ videoURL }) => {
  const videoRef = useRef(null);
  const text = "video in viewport";
  const style = "font-size: 18px; color: blue; background-color: yellow;";
  const [isVideoVisible, setIsVideoVisible] = useState(true);
  const [play, setPlay] = useState(true);
  const [volume, setVolume] = useState(false);
  const bannerData =
    "https://cdn.comverseglobal.com/bareeze/products/product_images/website-full-oct.mp4";
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0, // Adjust this threshold as needed
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVideoVisible(true);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(videoRef.current);

    // Clean up the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  const playPauseControl = (value) => {
    value === "play" ? setPlay(true) : setPlay(false);
  };

  const volumeMuteControl = (value) => {
    value === "volume" ? setVolume(false) : setVolume(true);
  };
  return (
    <div
      className={
        !isVideoVisible
          ? "video-player-main video-loading"
          : "video-player-main video-loaded"
      }
      style={{
        height: "100%",
        width: "100%",
        position: "relative",
      }}
    >
      <div ref={videoRef} style={{ height: "100%", width: "100%" }}>
        {isVideoVisible && (
          <div
            className="video-container"
            style={{ height: "auto", width: "100%" }}
          >
            <ReactPlayer
              className="mp4-player"
              url={videoURL}
              style={{
                objectFit: "cover",
              }}
              controls={false}
              width="100%"
              height="100%"
              volume={1}
              loop={true}
              playsinline={true}
              muted={volume ? false : true}
              autoPlay={true}
              playing={play && isVideoVisible ? true : false}
            />
            {/* <div
              className="play-pause-container"
              style={{ position: "absolute", top: "0px", right: "10px" }}
            >
              {play ? (
                <PauseCircleOutline
                  onClick={(e) => {
                    playPauseControl("pause");
                  }}
                />
              ) : (
                <PlayCircleOutline
                  onClick={(e) => {
                    playPauseControl("play");
                  }}
                />
              )}
            </div> */}
            <div
              className="volume-mute-container"
              style={{ position: "absolute", bottom: "50px", left: "64px" }}
            >
              {!volume ? (
                <VolumeOffIcon
                  onClick={(e) => {
                    volumeMuteControl("mute");
                  }}
                />
              ) : (
                <VolumeUpIcon
                  onClick={(e) => {
                    volumeMuteControl("volume");
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoPlayer;

import Link from "next/link";
import { useState, useEffect } from "react";
import { Col, Image, Carousel } from "antd";
import "./SingleBanner.scss";
import {
  SlickNextArrow,
  SlickPrevArrow,
} from "@/components/shared/Buttons/CarouselButtons/CarouselButtons";
import CustomSkeletonBox from "@/components/CustomSkeletonBox/CustomSkeletonBox";

export default function SingleBanner({ BannerImage, link }) {
  const [imageLoaded, setImageLoaded] = useState({});
  const onImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <Col className="singleBanner margin15" span={24}>
      <Link href={link ?? "#"}>
        <Image
          width="100%"
          height="auto"
          preview={false}
          src={BannerImage}
          onLoad={() => onImageLoad()}
          alt={BannerImage}
        />
      </Link>
    </Col>
  );
}

import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./BrandsSlider.scss";
import Link from "next/link";
import { Col, Typography } from "antd";
import "../HomePage.scss";

const { Text } = Typography;

export const BrandsSlider = ({ data, userAgent }) => {
  const slides = data?.brands;
  const { isDesktop } = userAgent;

  return (
    <div className="brandSlider marginBottom50 marginTop50">
      {data?.title ? (
        <Col
          span={24}
          className="sectionHeadingContainer side-bullet mt-10 mb-10"
        >
          <Text
            className={
              isDesktop
                ? "sectionHeadingBold"
                : "sectionHeadingMobileBold textCenter"
            }
          >
            {data?.title}
          </Text>
        </Col>
      ) : null}
      <div className="brandSlider__container">
        <div className="brandSlider__wrapper">
          {slides.concat(slides).map((slide, index) => (
            <div key={index} className="brandSlider__item">
              <Link
                href={slide.handle}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyLoadImage
                  src={slide.logo}
                  alt={slide.banner_slider_alt_text || "Brand logo"}
                  className="brandSlider__logo"
                />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

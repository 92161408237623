import React from "react";
import Link from "next/link";
import { Button } from "antd";
import { PlusOutlined, ShopOutlined } from "@ant-design/icons";
import "./HunarmandButtons.scss";

const HunarmandButtons = ({ userAgent }) => {
  const { isMobile } = userAgent || {};

  return (
    <div className={`hunarmand-buttons-container ${isMobile ? "mobile" : ""}`}>
      <div className="hunarmand-button-section">
        <p className="hunarmand-button-description">
          Want to register as Hunarmand ?
        </p>
        <Link href="/hunarmand-form?type=vendor">
          <Button
            type="primary"
            // icon={<ShopOutlined />}
            className="hunarmand-action-button vendor-button"
          >
            Click Here
          </Button>
        </Link>
      </div>

      <div className="hunarmand-button-section">
        <p className="hunarmand-button-description">
          Already a Hunarmand & want to add a new Product ?
        </p>
        <Link href="/hunarmand-form?type=product">
          <Button
            type="primary"
            // icon={<PlusOutlined />}
            className="hunarmand-action-button product-button"
          >
            Click Here
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default HunarmandButtons;

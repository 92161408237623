import React from "react";
import Link from "next/link";
import { Col, Typography } from "antd";
import "./CategoriesHeader.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { usePathname } from "next/navigation";

const { Text } = Typography;

export default function CategoriesHeader({ data, userAgent }) {
  const { isMobile } = userAgent;
  const pathName = usePathname();
  const categories = data?.header_list || [];
  return isMobile ? (
    <Col className="CategoriesHeader">
      {pathName === "/" ? (
        <Col span={24} className="sectionHeadingContainer">
          <Text className="sectionHeadingMobileBold textLeft">
            Trending Categories
          </Text>
        </Col>
      ) : null}
      <div className="category-header-inner">
        {data?.header_list?.map((catHead, index) => (
          <Link href={catHead?.handle} className="category-item" key={index}>
            <div className="category-content">
              <LazyLoadImage
                width="auto"
                height={15}
                src={catHead?.category_image}
                alt={catHead?.category_image_alt_text}
                className="category-icon"
              />
              <Text className="font12Light text-white">{catHead?.name}</Text>
            </div>
          </Link>
        ))}
      </div>
    </Col>
  ) : (
    <>
      <div className="categoriesHeaderDesktop marginBottom15 marginTop10">
        {categories.length > 0 && pathName === "/" && (
          <Col
            span={24}
            className="sectionHeadingContainer side-bullet text-center mt-10 mb-10"
          >
            <Text className="sectionHeadingBold">{data?.title}</Text>
          </Col>
        )}
        <div className="categoriesheader-categories">
          {categories.map((category, index) => (
            <Link
              href={category.handle || "#"}
              key={index}
              className="category-item"
            >
              <div className="category-content">
                <LazyLoadImage
                  src={category.category_image}
                  alt={category.category_image_alt_text}
                  className="category-icon"
                />
                <Text className="category-name">{category?.name}</Text>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}

"use client";
//? Library Imports ------------------------------------------------------------>
import Link from "next/link";
import { useEffect, useState } from "react";
import { Col, Row, Space, Typography, Image } from "antd";

//? Assets --------------------------------------------------------------------->
import "./MultiUsableLayout8.scss";
import CustomSkeletonBox from "@/components/CustomSkeletonBox/CustomSkeletonBox";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function MultiUsableLayout8(props) {
  //? Hooks ---------------------------------------------------------------------->
  const { isDesktop, isMobile } = props?.userAgent;

  //? States --------------------------------------------------------------------->

  return (
    <div className="multi-usable-layout">
      <div className="header-mus">
        <Text
          className={
            isMobile ? "font18Bold main-title" : "font26Bold main-title"
          }
        >
          {props?.data?.main_title}
        </Text>
        {/* <Text
          className={
            isMobile ? "font13Bold description" : "font20Bold description"
          }
        >
          Electronic equipments
        </Text> */}
      </div>

      <div className={`features ${isMobile ? "mobile" : "desktop"}`}>
        {props?.data?.features.map((feature, index) => (
          <Link href={feature?.link} key={index} className="feature-item">
            <Image
              preview={false}
              src={feature.img}
              alt={feature.alt_text || feature.title}
            />
            <Text
              className={
                isMobile ? "font8Light card-title" : "font20light card-title"
              }
            >
              {feature.title}
            </Text>
            {/* <Text className="discount">-40%</Text> */}
          </Link>
        ))}
      </div>
    </div>
  );
}

import Link from "next/link";
import { useState, useEffect } from "react";
import { Col, Image, Carousel } from "antd";
import "./BannerSwiper.scss";
import {
  SlickNextArrow,
  SlickPrevArrow,
} from "@/components/shared/Buttons/CarouselButtons/CarouselButtons";
import CustomSkeletonBox from "@/components/CustomSkeletonBox/CustomSkeletonBox";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

// Custom Next Arrow
const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "10px", zIndex: "1" }}
      onClick={onClick}
    >
      <ArrowForwardIosIcon />
    </div>
  );
};

// Custom Previous Arrow
const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "10px", zIndex: "1" }}
      onClick={onClick}
    >
      <ArrowBackIosIcon />
    </div>
  );
};

export default function BannerSwiper({ data, isMobile }) {
  const [loading, setLoading] = useState(true);
  const [showafterImageLoad, setShowafterImageLoad] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [slideData, setSlideData] = useState([]);
  const [imageLoaded, setImageLoaded] = useState({});

  const settings = {
    speed: 500,
    // dots: false,
    dots: true,
    swipe: isMobile ? true : false,
    // arrows: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 4000,
    variableWidth: false,
    adaptiveHeight: true,
    pauseOnHover: true,
    swipeToSlide: isMobile ? true : false,
    // prevArrow: <PrevArrow />,
    // nextArrow: <NextArrow />,
  };

  const onImageLoad = (index) => {
    setImageLoaded((prev) => ({ ...prev, [index]: true }));
  };

  useEffect(() => {
    if (data?.length) {
      setSlideData(data);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (Object.keys(imageLoaded).length > 0) {
      setTimeout(() => {
        setShowafterImageLoad(true);
      }, 600);
    }
  }, [imageLoaded]);

  return (
    <Col className="BannerSwiper margin15" span={24}>
      <div
        className="carousel_container"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {loading ? (
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1, marginRight: 16 }}>
              <CustomSkeletonBox width="100%" height={400} />
            </div>
          </div>
        ) : (
          <div style={{ display: showafterImageLoad ? "block" : "none" }}>
            <Carousel {...settings} swipeToSlide draggable>
              {slideData.map((banner, index) => (
                <Link href={banner?.link} key={index}>
                  <Image
                    width="100%"
                    height="400px"
                    preview={false}
                    src={banner?.img}
                    onLoad={() => onImageLoad(index)}
                    style={{
                      opacity: imageLoaded[index] ? 1 : 0,
                    }}
                    alt={
                      banner?.banner_slider_alt_text ||
                      banner?.img.split("/").pop()
                    }
                  />
                </Link>
              ))}
            </Carousel>
          </div>
        )}
        {!showafterImageLoad && (
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1, marginRight: 16 }}>
              <CustomSkeletonBox width="100%" height={400} />
            </div>
          </div>
        )}
      </div>
    </Col>
  );
}

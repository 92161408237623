"use client";
import { Carousel, Col, Typography } from "antd";
import "./ImagesCarousel.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Link from "next/link";

const { Text } = Typography;
export default function ImagesCarousel({ data, userAgent }) {
  const { isDesktop } = userAgent;
  const settings = {
    autoplaySpeed: 4000,
    dots: true,
    swipe: true,
    autoplay: true,
    infinite: true,
    slidesToScroll: 1,
    pauseOnHover: true,
  };

  const slides = data?.slides || [];

  return (
    <Col className="images-carousel marginBottom15 marginTop10">
      {data?.main_title ? (
        <Col
          span={24}
          className="sectionHeadingContainer side-bullet mt-10 mb-10"
        >
          <Text
            className={
              isDesktop
                ? "sectionHeadingBold"
                : "sectionHeadingMobileBold textCenter"
            }
          >
            {data?.main_title}
          </Text>
        </Col>
      ) : null}
      <div className="carousel-container">
        <Carousel {...settings} effect="fade">
          {slides.map((slide, index) => (
            <div key={index}>
              <Link href={slide?.link}>
                <LazyLoadImage
                  src={slide?.img}
                  //  alt={`Sample Image ${index + 1}`}
                  alt={slide?.banner_slider_alt_text}
                  className="image-carousel-images"
                  preview={false}
                  width={"100%"}
                />
              </Link>
            </div>
          ))}
        </Carousel>
      </div>
    </Col>
  );
}

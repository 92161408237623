"use client";

import Image from "next/image";
import nextBtn from "../../../../assets/images/carousel buttons/nextBtn.svg";
import prevBtn from "../../../../assets/images/carousel buttons/prevBtn.svg";

export const SlickNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        // // padding: "16px 20px",
        right: "2px",
        zIndex: 1,
        // background: "#ffffff",
        // display: "flex",
        // alignItems: "center",
        // justifyContent: "center",
        // borderRadius: "5px 0px 0px 5px",
      }}
      onClick={onClick}
    >
      <span className="next-btn-container">
        {/* <MdArrowForwardIos
            style={{ color: "#000000", zIndex: 1, fontSize: "40px" }}
          /> */}

        <Image src={nextBtn} alt="next button" width={"50px"} />
      </span>
    </div>
  );
};

export const SlickPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        // ...style,
        // padding: "16px 20px",
        // background: "#ffffff",
        left: "3px",
        zIndex: 1,
        // display: "flex",
        // alignItems: "center",
        // justifyContent: "center",
        // borderRadius: "0px 5px 5px 0px",
      }}
      onClick={onClick}
    >
      {/* <MdArrowBackIos
            style={{
              color: "#000000",
              zIndex: 1,
              fontSize: "16px",
            }}

          /> */}
      <span className="prev-btn-container">
        <Image src={prevBtn} alt="prev button" width={"50px"} />
      </span>
    </div>
  );
};

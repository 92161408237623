"use client";

import React from "react";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCountryId,
  changeCountry,
  changeCountryCode,
  changeCurrency,
} from "../../redux/Slices/multiLocationSlice";

import dynamic from "next/dynamic";

//? Assets --------------------------------------------------------------------->
import "../../components/Homepage/HomePage.scss";

//? Modules -------------------------------------------------------------------->
import TabsSection from "@/components/Homepage/TabsSection/TabsSection";
import BannerSwiper from "@/components/Homepage/BannerSwiper/BannerSwiper";
import BannerCarousel from "@/components/Homepage/BannerCarousel/BannerCarousel";
import CategoriesHeader from "@/components/Homepage/CategoriesHeader/CategoriesHeader";
import SingleBanner from "../Homepage/SingleBanner/SingleBanner";
import ImagesCarousel from "../Homepage/ImagesCarousel/ImagesCarousel";
import { BrandsSlider } from "../Homepage/BrandsSlider/BrandsSlider";
import MultiUsableLayout8 from "../Homepage/MultiUsableLayout8/MultiUsableLayout8";
import useBrowserBackButton from "../globalBackButton/BackButton";
import { Col, Row } from "antd";
import { usePathname } from "next/navigation";
import VideoPlayer from "../VideoBanner/LazyVideoPlayer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Link from "next/link";
import BannerImageSrc from "../../assets/images/1800x1800.jpg";
import BannerImageSrcMen from "../../assets/images/1800x1800-men.jpg";
import Dealskakhazaza from "../../assets/images/Deals-ka-khazaza.gif";

import SaleCounter from "../SaleCounter/SaleCounter";
import HunarmandProducts from "../HumdardPage/HumdardProducts";
import usePath from "@/hooks/usePath/usePath";
import HunarmandButtons from "../HumdardPage/HunarmadButtons";

const MultiUsableComp = dynamic(() =>
  import("@/components/Homepage/MultiUsableComp/MultiUsableComp")
);

const ProductsCarousel = dynamic(() =>
  import("@/components/shared/ProductsCarousel/ProductsCarousel")
);

const BrandsCollection = dynamic(() =>
  import("@/components/Homepage/BrandsCollection/BrandsCollection")
);

const ProductsGroupComp = dynamic(() =>
  import("@/components/Homepage/ProductsGroupComp/ProductsGroupComp")
);

const CataloguesCarousel = dynamic(() =>
  import("@/components/Homepage/CataloguesCarousel/CataloguesCarousel")
);

const FilterByGenderSection = dynamic(() =>
  import("@/components/Homepage/FilterByGenderSection/FilterByGenderSection")
);

const BannerSection = dynamic(() =>
  import("@/components/Homepage/BannerSection/BannerSection")
);

const LandingPage = ({ homePageData, userAgent }) => {
  const path = usePath();
  const comverse_customer_name = useSelector(
    (state) => state.accountSlice?.comverse_customer_name
  );

  const customerToken = Cookies.get("comverse_customer_token");

  const [DashboardDetails, setDashboardDetails] = useState({});
  const now = new Date();
  const targetDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    17,
    0,
    0
  ); // 5 PM today

  // If the target time has already passed today, set it for tomorrow at 5 PM
  if (now > targetDate) {
    targetDate.setDate(targetDate.getDate() + 1); // Set for tomorrow
  }
  useBrowserBackButton(() => {});
  const fetchDashboardDetails = async () => {
    if (customerToken) {
      try {
        const headers = {
          "Content-Type": "application/json",
        };
        headers.Authorization = customerToken;

        const payload = {
          method: "GET",
          headers: headers,
        };

        const res = await fetch(
          process.env.NEXT_PUBLIC_BACKEND + "/storefront/customer_dashboard",
          payload
        );

        const data = await res.json();

        if (!res.ok) {
          if (data?.detail === "Invalid auth token") {
            customerLogout();
            dispatch(logoutReducer());
            router.push("/");
          }
          //logout here
          throw new Error("Api Failed!");
        }

        setDashboardDetails(data);
        return data;
      } catch (error) {
        console.error("Error fetching data:", error.message);
        return null;
      }
    } else {
      setDashboardDetails({});
    }
  };
  useEffect(() => {
    fetchDashboardDetails();
  }, [comverse_customer_name, customerToken]);

  const { isMobile, isTablet, isDesktop } = userAgent;
  const pathname = usePathname();
  const BrandsDataArray = {
    type: "brands_slider",
    title: "",
    brands: [
      {
        name: "Apple",
        handle: "mobile-phones-tablets/smart-phones/1111-apple",
        logo: "https://alfa-cdn.comverseglobal.com/alfa/products/product_images/apple.png",
      },
      {
        name: "Samsung",
        handle: "samsung-8",
        logo: "https://alfa-cdn.comverseglobal.com/alfa/products/product_images/samsung.png",
      },
      {
        name: "Infinix",
        handle: "infinix-4",
        logo: "https://alfa-cdn.comverseglobal.com/alfa/products/product_images/infinix.png",
      },
      {
        name: "Oppo",
        handle: "oppo-2",
        logo: "https://alfa-cdn.comverseglobal.com/alfa/products/product_images/oppo.png",
      },
      {
        name: "Vivo",
        handle: "mobile-phones-tablets/smart-phones/1111-vivo",
        logo: "https://alfa-cdn.comverseglobal.com/alfa/products/product_images/vivo.png",
      },
      {
        name: "Xioami",
        handle: "mobile-phones-tablets/smart-phones/1111-xiaomi",
        logo: "https://alfa-cdn.comverseglobal.com/alfa/products/product_images/xiaomi.png",
      },
    ],
  };

  return (
    <div className="landing-page-inner">
      {pathname === "/11-11" && <SaleCounter targetDate={targetDate} />}
      {pathname === "/fashion" && (
        <VideoPlayer videoURL="https://alfa-cdn.comverseglobal.com/alfa/products/product_images/winter-2.mp4" />
      )}
      {/* {pathname === "/premier" && <div>hello</div>} */}
      {homePageData?.homepage?.map((section, index) => {
        return (
          <div key={index}>
            {section?.type === "banner_slider" && (
              <>
                {!isMobile && section?.section_type === "layout_3" && (
                  <div className="marginBottom15 marginTop10">
                    <Row
                      style={{
                        display: "flex",
                      }}
                    >
                      <Col span={12} className="banner-swiper-alfa">
                        <BannerSwiper
                          data={section?.slides}
                          isMobile={isMobile}
                        />
                      </Col>
                      <Col span={6} className="banner-right">
                        <SingleBanner
                          link={section?.outside_banner_1_link}
                          BannerImage={section?.outside_banner_1}
                        />
                        <SingleBanner
                          link={section?.outside_banner_2_link}
                          BannerImage={section?.outside_banner_2}
                        />
                      </Col>
                    </Row>
                  </div>
                )}

                {isDesktop && section?.section_type === "layout_2" && (
                  <>
                    <ImagesCarousel data={section} userAgent={userAgent} />
                  </>
                )}

                {section?.section_type === "layout_1" && (
                  <BannerCarousel
                    DashboardDetails={DashboardDetails}
                    customerToken={customerToken}
                    data={section}
                    userAgent={{ isMobile, isTablet, isDesktop }}
                  />
                )}
                {isMobile && section?.section_type === "layout_2" && (
                  <BannerCarousel
                    DashboardDetails={DashboardDetails}
                    customerToken={customerToken}
                    data={section}
                    userAgent={{ isMobile, isTablet, isDesktop }}
                  />
                )}
              </>
            )}

            {section?.type === "banner_section" && (
              <div>
                <BannerSection data={section} userAgent={userAgent} />
              </div>
            )}

            {section?.type === "brands_slider" && (
              <BrandsSlider
                data={pathname === "/11-11" ? BrandsDataArray : section}
                userAgent={userAgent}
              />
            )}
            {section?.type === "catalogues_carousel" && (
              <div
                style={{
                  maxWidth: "1232px",
                  margin: isDesktop ? "36px auto" : "",
                }}
              >
                <CataloguesCarousel
                  data={section}
                  userAgent={{ isMobile, isTablet, isDesktop }}
                />
              </div>
            )}

            {section?.type === "products_carousel" && (
              <div
                className="product-carousel-main"
                style={{
                  margin: "auto",
                  maxWidth: "100%",
                }}
              >
                {/* <ProductsCarousel
                  data={
                    section?.select_type === "collections_section"
                      ? section?.collections[0].products
                      : section?.select_type === "categories_section"
                      ? section?.categories[0].products
                      : null
                  }
                  title={section?.title}
                  userAgent={{ isMobile, isTablet, isDesktop }}
                  type={"homepage"}
                /> */}
                {/* {path === "/hunarmand" || path === "/hunarmand-uat" ? (
                  <HunarmandProducts
                    data={
                      section?.select_type === "collections_section"
                        ? section?.collections[0]
                        : section?.select_type === "categories_section"
                        ? section?.categories[0]
                        : null
                    }
                    title={section?.title}
                    description={section?.description}
                    userAgent={{ isMobile, isTablet, isDesktop }}
                    buttonText={section?.button_text}
                    buttonLink={section?.button_link}
                    buttonColor={section?.button_color}
                  />
                ) : (
                  <ProductsCarousel
                    data={
                      section?.select_type === "collections_section"
                        ? section?.collections[0].products
                        : section?.select_type === "categories_section"
                        ? section?.categories[0].products
                        : null
                    }
                    title={section?.title}
                    userAgent={{ isMobile, isTablet, isDesktop }}
                    type={"homepage"}
                  />
                )} */}
                {
                  // Check if path is hunarmand AND isDesktop is true
                  (path === "/hunarmand" || path === "/hunarmand-uat") &&
                  isDesktop ? (
                    <HunarmandProducts
                      data={
                        section?.select_type === "collections_section"
                          ? section?.collections[0]
                          : section?.select_type === "categories_section"
                          ? section?.categories[0]
                          : null
                      }
                      title={section?.title}
                      description={section?.description}
                      userAgent={{ isMobile, isTablet, isDesktop }}
                      buttonText={section?.button_text}
                      buttonLink={section?.button_link}
                      buttonColor={section?.button_color}
                    />
                  ) : (
                    // In all other cases (either different path OR hunarmand path with isDesktop false)
                    <ProductsCarousel
                      data={
                        section?.select_type === "collections_section"
                          ? section?.collections[0].products
                          : section?.select_type === "categories_section"
                          ? section?.categories[0].products
                          : null
                      }
                      title={section?.title}
                      userAgent={{ isMobile, isTablet, isDesktop }}
                      buttonText={section?.button_text}
                      buttonLink={section?.button_link}
                      buttonColor={section?.button_color}
                      type={"homepage"}
                    />
                  )
                }
              </div>
            )}

            {section?.type === "multi_usable_section" &&
              section?.section_type === "layout_8" && (
                <div>
                  <MultiUsableLayout8
                    data={section}
                    userAgent={{ isMobile, isTablet, isDesktop }}
                  />
                </div>
              )}
            {section?.type === "multi_usable_section" &&
              section?.section_type !== "layout_8" && (
                <MultiUsableComp
                  data={section}
                  userAgent={{ isMobile, isTablet, isDesktop }}
                />
              )}

            {section?.type === "category_filters" && (
              <div>
                <FilterByGenderSection data={section} userAgent={userAgent} />
              </div>
            )}

            {/* {section?.type === "collections_section" && (
              <div>
                <ProductsGroupComp data={section} userAgent={userAgent} />
              </div>
            )} */}
            {section?.type === "categories_header_section" && (
              <div>
                <CategoriesHeader data={section} userAgent={userAgent} />
              </div>
            )}
          </div>
        );
      })}
      {pathname === "/fashion" ? (
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <Col className="banner-item" style={{ flex: "0 0 49%" }}>
            <Link href="/women-fahion">
              <LazyLoadImage
                src={BannerImageSrc.src}
                alt={BannerImageSrc.src}
                width="100%"
                height="auto"
                className="banner-image"
              />
            </Link>
          </Col>{" "}
          <Col className="banner-item" style={{ flex: "0 0 49%" }}>
            <Link href="/men-fashion">
              <LazyLoadImage
                src={BannerImageSrcMen.src}
                alt={BannerImageSrcMen.src}
                width="100%"
                height="auto"
                className="banner-image"
              />
            </Link>
          </Col>
        </Row>
      ) : null}

      {pathname === "/11-11" && (
        <Col
          className="banner-item"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Link href="#" style={{}}>
            <LazyLoadImage
              src={Dealskakhazaza.src}
              alt={Dealskakhazaza.src}
              width="100%"
              height="auto"
              className="banner-image"
            />
          </Link>
        </Col>
      )}
      {/* : null} */}

      {path === "/hunarmand" || path === "/hunarmand-uat" ? (
        <div>
          <HunarmandButtons userAgent={userAgent} />
        </div>
      ) : null}
    </div>
  );
};

export default LandingPage;
